exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-painter-js": () => import("./../../../src/pages/painter.js" /* webpackChunkName: "component---src-pages-painter-js" */),
  "component---src-pages-psychologist-js": () => import("./../../../src/pages/psychologist.js" /* webpackChunkName: "component---src-pages-psychologist-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-painter-gallery-js": () => import("./../../../src/templates/painter-gallery.js" /* webpackChunkName: "component---src-templates-painter-gallery-js" */)
}

